define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/topic-above-post-stream/category-expert-question-banner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{topic-category-expert-question-banner
    topic=this.model
    editFirstPost=this.editFirstPost
  }}
  */
  {
    "id": "q7JSpcVo",
    "block": "[[[1,[28,[35,0],null,[[\"topic\",\"editFirstPost\"],[[30,0,[\"model\"]],[30,0,[\"editFirstPost\"]]]]]]],[],false,[\"topic-category-expert-question-banner\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/topic-above-post-stream/category-expert-question-banner.hbs",
    "isStrictMode": false
  });
});