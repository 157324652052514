define("discourse/plugins/discourse-global-communities/discourse/raw-templates/webinar-list-item", ["exports", "discourse-common/lib/raw-handlebars", "discourse-common/lib/raw-templates"], function (_exports, _rawHandlebars, _rawTemplates) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let template = (0, _rawHandlebars.template)({
    "1": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "  <div class=\"mini-webinar-item\">\n    " + alias3((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias2).call(alias1, "member-squid", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 3,
            "column": 4
          },
          "end": {
            "line": 3,
            "column": 29
          }
        }
      })) + "\n    <span class=\"mini-webinar-label\">" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, "zoom.hosted_by", {
        "name": "i18n",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 4,
            "column": 37
          },
          "end": {
            "line": 4,
            "column": 62
          }
        }
      })) + "</span>\n    <a href=\"/u/" + alias3(lookupProperty(helpers, "get").call(alias1, "webinar.host.username", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 5,
            "column": 16
          },
          "end": {
            "line": 5,
            "column": 41
          }
        }
      })) + "\" data-user-card=\"" + alias3(lookupProperty(helpers, "get").call(alias1, "webinar.host.username", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 5,
            "column": 59
          },
          "end": {
            "line": 5,
            "column": 84
          }
        }
      })) + "\" >" + alias3((lookupProperty(helpers, "avatar") || depth0 && lookupProperty(depth0, "avatar") || alias2).call(alias1, "webinar.host", {
        "name": "avatar",
        "hash": {
          "imageSize": "small"
        },
        "hashTypes": {
          "imageSize": "StringLiteral"
        },
        "hashContexts": {
          "imageSize": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 5,
            "column": 87
          },
          "end": {
            "line": 5,
            "column": 128
          }
        }
      })) + "</a>\n  </div>\n";
    },
    "3": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "  <div class=\"mini-webinar-item\">\n   " + alias3((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias2).call(alias1, "comment-squid", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 11,
            "column": 3
          },
          "end": {
            "line": 11,
            "column": 29
          }
        }
      })) + " <span class=\"mini-webinar-label\">" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, "zoom.panelists", {
        "name": "i18n",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 11,
            "column": 63
          },
          "end": {
            "line": 11,
            "column": 88
          }
        }
      })) + "</span>\n    <span class=\"panelist-avatars\">\n" + ((stack1 = lookupProperty(helpers, "each").call(alias1, "panelist", "in", "webinar.panelists", {
        "name": "each",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(4, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression", "CommentStatement", "PathExpression"],
        "contexts": [depth0, depth0, depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 13,
            "column": 6
          },
          "end": {
            "line": 15,
            "column": 15
          }
        }
      })) != null ? stack1 : "") + "    </span>\n  </div>\n";
    },
    "4": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "        <a href=\"/u/" + alias2(lookupProperty(helpers, "get").call(alias1, "panelist.username", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 14,
            "column": 20
          },
          "end": {
            "line": 14,
            "column": 41
          }
        }
      })) + "\" data-user-card=\"" + alias2(lookupProperty(helpers, "get").call(alias1, "panelist.username", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 14,
            "column": 59
          },
          "end": {
            "line": 14,
            "column": 80
          }
        }
      })) + "\" >" + alias2((lookupProperty(helpers, "avatar") || depth0 && lookupProperty(depth0, "avatar") || container.hooks.helperMissing).call(alias1, "panelist", {
        "name": "avatar",
        "hash": {
          "imageSize": "small"
        },
        "hashTypes": {
          "imageSize": "StringLiteral"
        },
        "hashContexts": {
          "imageSize": depth0
        },
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 14,
            "column": 83
          },
          "end": {
            "line": 14,
            "column": 120
          }
        }
      })) + "</a>\n";
    },
    "6": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "  <div class=\"mini-webinar-item\">\n" + ((stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "webinarStarted", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(7, data, 0),
        "inverse": container.program(9, data, 0),
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 27,
            "column": 4
          },
          "end": {
            "line": 42,
            "column": 11
          }
        }
      })) != null ? stack1 : "") + "  </div>\n";
    },
    "7": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "      <a class=\"webinar-join-sdk btn btn-primary\">" + alias3((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias2).call(alias1, "video", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 28,
            "column": 50
          },
          "end": {
            "line": 28,
            "column": 68
          }
        }
      })) + " <span>" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, "zoom.join_sdk", {
        "name": "i18n",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 28,
            "column": 75
          },
          "end": {
            "line": 28,
            "column": 99
          }
        }
      })) + "</span></a>\n";
    },
    "9": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return (stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "canRegister", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(10, data, 0),
        "inverse": container.program(12, data, 0),
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 30,
            "column": 6
          },
          "end": {
            "line": 41,
            "column": 13
          }
        }
      })) != null ? stack1 : "";
    },
    "10": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "        <a class=\"webinar-register-button btn btn-primary\">" + alias3((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias2).call(alias1, "far-calendar-alt", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 31,
            "column": 59
          },
          "end": {
            "line": 31,
            "column": 88
          }
        }
      })) + " <span>" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, "zoom.register", {
        "name": "i18n",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 31,
            "column": 95
          },
          "end": {
            "line": 31,
            "column": 119
          }
        }
      })) + "</span></a>\n";
    },
    "12": function (container, depth0, helpers, partials, data) {
      var stack1,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "        <span class=\"webinar-registered\">\n" + ((stack1 = lookupProperty(helpers, "if").call(depth0 != null ? depth0 : container.nullContext || {}, "isAttendee", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(13, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 34,
            "column": 10
          },
          "end": {
            "line": 39,
            "column": 17
          }
        }
      })) != null ? stack1 : "") + "        </span>\n";
    },
    "13": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "            " + alias3((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias2).call(alias1, "far-check-circle", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 35,
            "column": 12
          },
          "end": {
            "line": 35,
            "column": 41
          }
        }
      })) + " " + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, "zoom.registered", {
        "name": "i18n",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 35,
            "column": 42
          },
          "end": {
            "line": 35,
            "column": 68
          }
        }
      })) + "\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "registrationOpen", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(14, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 36,
            "column": 12
          },
          "end": {
            "line": 38,
            "column": 19
          }
        }
      })) != null ? stack1 : "");
    },
    "14": function (container, depth0, helpers, partials, data) {
      var alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.hooks.helperMissing,
        alias3 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return "              <a class=\"btn-flat webinar-unregister-button\" title=\"" + alias3((lookupProperty(helpers, "i18n") || depth0 && lookupProperty(depth0, "i18n") || alias2).call(alias1, "zoom.cancel_registration", {
        "name": "i18n",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 37,
            "column": 67
          },
          "end": {
            "line": 37,
            "column": 102
          }
        }
      })) + "\">" + alias3((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || alias2).call(alias1, "times", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 37,
            "column": 104
          },
          "end": {
            "line": 37,
            "column": 122
          }
        }
      })) + "</a>\n";
    },
    "compiler": [8, ">= 4.3.0"],
    "main": function (container, depth0, helpers, partials, data) {
      var stack1,
        alias1 = depth0 != null ? depth0 : container.nullContext || {},
        alias2 = container.escapeExpression,
        lookupProperty = container.lookupProperty || function (parent, propertyName) {
          if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
            return parent[propertyName];
          }
          return undefined;
        };
      return ((stack1 = lookupProperty(helpers, "if").call(alias1, "webinar.host", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(1, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 7
          }
        }
      })) != null ? stack1 : "") + "\n" + ((stack1 = lookupProperty(helpers, "if").call(alias1, "webinar.panelists", {
        "name": "if",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(3, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 9,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 7
          }
        }
      })) != null ? stack1 : "") + "\n<div class=\"mini-webinar-item webinar-time\">\n  " + alias2((lookupProperty(helpers, "d-icon") || depth0 && lookupProperty(depth0, "d-icon") || container.hooks.helperMissing).call(alias1, "time-squid", {
        "name": "d-icon",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["StringLiteral"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 21,
            "column": 2
          },
          "end": {
            "line": 21,
            "column": 25
          }
        }
      })) + "\n  <span class=\"mini-webinar-label\">" + alias2(lookupProperty(helpers, "get").call(alias1, "webinarTime", {
        "name": "get",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 22,
            "column": 35
          },
          "end": {
            "line": 22,
            "column": 50
          }
        }
      })) + "</span>\n</div>\n\n" + ((stack1 = lookupProperty(helpers, "unless").call(alias1, "webinarEnded", {
        "name": "unless",
        "hash": {},
        "hashTypes": {},
        "hashContexts": {},
        "fn": container.program(6, data, 0),
        "inverse": container.noop,
        "types": ["PathExpression"],
        "contexts": [depth0],
        "data": data,
        "loc": {
          "start": {
            "line": 25,
            "column": 0
          },
          "end": {
            "line": 44,
            "column": 11
          }
        }
      })) != null ? stack1 : "");
    },
    "useData": true
  });
  (0, _rawTemplates.addRawTemplate)("javascripts/webinar-list-item", template, {
    core: false,
    pluginName: "discourse-global-communities",
    hasModernReplacement: false
  });
  var _default = _exports.default = template;
});