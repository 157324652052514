define("discourse/plugins/discourse-global-communities/discourse/connectors/above-main-container/group-analytics", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* eslint-disable ember/jquery-ember-run */

  function findGroupName(groupNames, $element) {
    const elements = $element.closest(".group, .group-card, .group-box");
    for (let i = 0; i < elements.length; ++i) {
      for (let j = 0; j < elements[i].classList.length; ++j) {
        let name = elements[i].classList[j];

        // In group pages, the group name is the class name.
        if (groupNames.has(name)) {
          return name;
        }

        // In group cards, the group name is the class name prefixed with
        // "group-card-".
        if (name.startsWith("group-card-")) {
          name = name.substr("group-card-".length);
          if (groupNames.has(name)) {
            return name;
          }
        }
      }
    }
  }
  var _default = _exports.default = {
    setupComponent(args, component) {
      component.setProperties({
        didInsertElement() {
          this._super(...arguments);
          if (!component.site || !component.site.groups) {
            return;
          }
          const site = component.site;
          const groupNames = new Set(site.groups.map(group => group.name));
          (0, _jquery.default)("#main").on("click.group-analytics", ".group-box", function () {
            const groupName = findGroupName(groupNames, (0, _jquery.default)(this));
            if (groupName) {
              window.localStorage.lastAgcAnalyticsRef = `group_${groupName}`;
            }
          });
          (0, _jquery.default)("#main").on("click.group-analytics", ".group-index-join", function () {
            const groupName = findGroupName(groupNames, (0, _jquery.default)(this));
            if (groupName) {
              component.appEvents.trigger("enhanced_user_analytics:event", "group_join", {
                group_name: groupName
              });
            }
          });
          (0, _jquery.default)("#main").on("click.group-analytics", ".group-index-leave", function () {
            const groupName = findGroupName(groupNames, (0, _jquery.default)(this));
            if (groupName) {
              component.appEvents.trigger("enhanced_user_analytics:event", "group_leave", {
                group_name: groupName
              });
            }
          });
          (0, _jquery.default)("#main").on("click.group-analytics", ".groups-header-filters .groups-header-filters-type .select-kit-row", event => {
            const $row = (0, _jquery.default)(event.target).closest(".select-kit-row");
            window.localStorage.lastAgcAnalyticsRef = `groups_filters_${$row.data("value") || "none"}`;
          });
          (0, _jquery.default)("#main").on("click.group-analytics", ".group-nav .group-dropdown .select-kit-row", () => {
            window.localStorage.lastAgcAnalyticsRef = "group_name_dropdown";
          });
          (0, _jquery.default)("#main").on("click.group-analytics", ".group-nav li a", event => {
            let tabName = "unknown";
            for (let i = 0; i < event.target.classList.length; ++i) {
              if (event.target.classList[i] !== "ember-view" && event.target.classList[i] !== "active") {
                tabName = event.target.classList[i];
                break;
              }
            }
            window.localStorage.lastAgcAnalyticsRef = `group_nav_${tabName}`;
          });
          (0, _jquery.default)("#main-outlet").on("click.user-analytics", ".mention", function (event) {
            window.localStorage.lastAgcAnalyticsRef = "user_mention";
            const mentionElement = event.target.closest(".mention");
            const username = mentionElement.text.replace(/^@/, "");
            component.appEvents.trigger("enhanced_user_analytics:event", "user_mention_click", {
              username
            });
          });
          (0, _jquery.default)("#main-outlet").on("click.group-analytics", ".mention-group", function (event) {
            window.localStorage.lastAgcAnalyticsRef = "group_mention";
            const mentionElement = event.target.closest(".mention-group");
            const groupName = mentionElement.text.replace(/^@/, "");
            component.appEvents.trigger("enhanced_user_analytics:event", "group_mention_click", {
              group_name: groupName
            });
          });
          const {
            events
          } = _jquery.default._data((0, _jquery.default)("#main-outlet")[0]);
          events.click.unshift(events.click.pop());
        },
        willDestroyElement() {
          this._super(...arguments);
          (0, _jquery.default)("#main").off("click.group-analytics");
          (0, _jquery.default)("#main-outlet").off("click.user-analytics");
          (0, _jquery.default)("#main-outlet").off("click.group-analytics");
        }
      });
    }
  };
});