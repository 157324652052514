define("discourse/plugins/discourse-global-communities/discourse/initializers/insert-video-addition", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "insert-video-addition",
    initialize(container) {
      const appEvents = container.lookup("service:app-events");
      appEvents.on("discourse-insert-video:video-inserted", text => {
        try {
          const composerController = container.lookup("service:composer");
          if (!composerController) {
            return;
          }
          const currentValue = composerController.get("model.reply");
          if (!currentValue) {
            return;
          }

          // Find the position of the newly inserted text
          const startIndex = currentValue.indexOf(text);
          if (startIndex !== -1) {
            // Define your wrapping texts
            const prefix = '<div data-theme-video="videojs">\n'; // or whatever prefix you want
            const suffix = "\n</div>"; // or whatever suffix you want

            // Construct the new value
            const newValue = currentValue.substring(0, startIndex) + prefix + text + suffix + currentValue.substring(startIndex + text.length);

            // Set the new value
            composerController.set("model.reply", newValue);
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e);
        }
      });
    }
  };
});