define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/search-menu-results-bottom/see-all-results", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <AgcQuickSearchMenuSeeAllResults
    @inTopicContext={{this.inTopicContext}}
    @onLinkClicked={{this.onLinkClicked}}
    @searchTopics={{this.searchTopics}}
    @closeSearchMenu={{this.closeSearchMenu}}
  />
  */
  {
    "id": "+7nLzDeH",
    "block": "[[[8,[39,0],null,[[\"@inTopicContext\",\"@onLinkClicked\",\"@searchTopics\",\"@closeSearchMenu\"],[[30,0,[\"inTopicContext\"]],[30,0,[\"onLinkClicked\"]],[30,0,[\"searchTopics\"]],[30,0,[\"closeSearchMenu\"]]]],null]],[],false,[\"agc-quick-search-menu-see-all-results\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/search-menu-results-bottom/see-all-results.hbs",
    "isStrictMode": false
  });
});