define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/menu-item-end/agc-notifications-secondary-dropdown", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="agc-secondary-actions-dropdown">
    <DMenu
      @icon="ellipsis-h"
      @identifier="agc-secondary-actions-dropdown"
      @placement="bottom-end"
    >
      <:content as |args|>
        <NotificationsActionsDropdown
          @close={{args.close}}
          @notification={{this.outletArgs.notification}}
        />
      </:content>
    </DMenu>
  </span>
  */
  {
    "id": "RLUHF1n3",
    "block": "[[[10,1],[14,0,\"agc-secondary-actions-dropdown\"],[12],[1,\"\\n  \"],[8,[39,0],null,[[\"@icon\",\"@identifier\",\"@placement\"],[\"ellipsis-h\",\"agc-secondary-actions-dropdown\",\"bottom-end\"]],[[\"content\"],[[[[1,\"\\n      \"],[8,[39,1],null,[[\"@close\",\"@notification\"],[[30,1,[\"close\"]],[30,0,[\"outletArgs\",\"notification\"]]]],null],[1,\"\\n    \"]],[1]]]]],[1,\"\\n\"],[13]],[\"args\"],false,[\"d-menu\",\"notifications-actions-dropdown\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/menu-item-end/agc-notifications-secondary-dropdown.hbs",
    "isStrictMode": false
  });
});