define("discourse/plugins/discourse-global-communities/discourse/connectors/move-to-topic-after-radio-buttons/copy-posts-checkbox", ["exports", "@glimmer/component", "@ember/modifier", "@ember/object", "@ember/service", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _modifier, _object, _service, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class CopyPostsCheckbox extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "agcMovePosts", [_service.service]))();
    #agcMovePosts = (() => (dt7948.i(this, "agcMovePosts"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    constructor() {
      super(...arguments);
      this.agcMovePosts.copyPostsOnMove = true;
    }
    onCheck(e) {
      this.agcMovePosts.copyPostsOnMove = e.target.checked;
    }
    static #_3 = (() => dt7948.n(this.prototype, "onCheck", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.siteSettings.agc_merged_topics_enabled}}
          <label
            for="agc-copy-posts-checkbox"
            class="checkbox-label agc-copy-posts-checkbox"
          >
            <input
              id="agc-copy-posts-checkbox"
              type="checkbox"
              checked={{this.agcMovePosts.copyPostsOnMove}}
              {{on "input" this.onCheck}}
            />{{i18n "discourse_gc.copy_moved_posts"}}</label>
        {{/if}}
      
    */
    {
      "id": "1R/4ETnO",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"siteSettings\",\"agc_merged_topics_enabled\"]],[[[1,\"      \"],[10,\"label\"],[14,\"for\",\"agc-copy-posts-checkbox\"],[14,0,\"checkbox-label agc-copy-posts-checkbox\"],[12],[1,\"\\n        \"],[11,\"input\"],[24,1,\"agc-copy-posts-checkbox\"],[16,\"checked\",[30,0,[\"agcMovePosts\",\"copyPostsOnMove\"]]],[24,4,\"checkbox\"],[4,[32,0],[\"input\",[30,0,[\"onCheck\"]]],null],[12],[13],[1,[28,[32,1],[\"discourse_gc.copy_moved_posts\"],null]],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/connectors/move-to-topic-after-radio-buttons/copy-posts-checkbox.js",
      "scope": () => [_modifier.on, _discourseI18n.i18n],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = CopyPostsCheckbox;
});