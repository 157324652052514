define("discourse/plugins/discourse-global-communities/discourse/services/agc-move-posts", ["exports", "@ember/service"], function (_exports, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AgcMovePostsService extends _service.default {
    // This service is a funny one.. At the time of writing, it's only used to hold one variable.
    // This value is set in a connector component rendered in the move posts modal. A checkbox
    // controls the value. Then when moving posts, there is a value transformer that looks at this
    // value to determine is { freeze_original: true } should be added to the move posts payload.
    copyPostsOnMove = true;
  }
  _exports.default = AgcMovePostsService;
});