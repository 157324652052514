define("discourse/plugins/discourse-global-communities/discourse/templates/components/gc-combo-box-header", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#each this.icons as |icon|}}
    {{d-icon icon}}
  {{/each}}
  
  <span class="selected-name">
    {{i18n this.options.headerLabel}}
    {{html-safe this.label}}
  </span>
  
  {{#if this.shouldDisplayClearableButton}}
    <button
      class="btn-clear"
      type="button"
      {{action this.onClearSelection bubbles=false}}
    >
      {{d-icon "times"}}
    </button>
  {{/if}}
  
  {{d-icon this.caretIcon class="caret-icon"}}
  */
  {
    "id": "yA1sM2Cb",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"icons\"]]],null]],null],null,[[[1,\"  \"],[1,[28,[35,2],[[30,1]],null]],[1,\"\\n\"]],[1]],null],[1,\"\\n\"],[10,1],[14,0,\"selected-name\"],[12],[1,\"\\n  \"],[1,[28,[35,3],[[30,0,[\"options\",\"headerLabel\"]]],null]],[1,\"\\n  \"],[1,[28,[35,4],[[30,0,[\"label\"]]],null]],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[41,[30,0,[\"shouldDisplayClearableButton\"]],[[[1,\"  \"],[11,\"button\"],[24,0,\"btn-clear\"],[24,4,\"button\"],[4,[38,6],[[30,0],[30,0,[\"onClearSelection\"]]],[[\"bubbles\"],[false]]],[12],[1,\"\\n    \"],[1,[28,[35,2],[\"times\"],null]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[1,[28,[35,2],[[30,0,[\"caretIcon\"]]],[[\"class\"],[\"caret-icon\"]]]]],[\"icon\"],false,[\"each\",\"-track-array\",\"d-icon\",\"i18n\",\"html-safe\",\"if\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/components/gc-combo-box-header.hbs",
    "isStrictMode": false
  });
});