define("discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-summary-top-category-row/agc-user-summary-top-category-row", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <td class="category-link">
    {{category-link this.category allowUncategorized="true" hideParent=false}}
  </td>
  <td class="topic-count">
    {{d-icon "comment"}}
    <UserSummaryCategorySearch
      @user={{this.user}}
      @category={{this.category}}
      @count={{this.category.topic_count}}
    />
  </td>
  <td class="reply-count">
    {{d-icon "reply"}}
    <UserSummaryCategorySearch
      @user={{this.user}}
      @category={{this.category}}
      @count={{this.category.post_count}}
    />
  </td>
  */
  {
    "id": "Lfw7vFwj",
    "block": "[[[10,\"td\"],[14,0,\"category-link\"],[12],[1,\"\\n  \"],[1,[28,[35,0],[[30,0,[\"category\"]]],[[\"allowUncategorized\",\"hideParent\"],[\"true\",false]]]],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"td\"],[14,0,\"topic-count\"],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"comment\"],null]],[1,\"\\n  \"],[8,[39,2],null,[[\"@user\",\"@category\",\"@count\"],[[30,0,[\"user\"]],[30,0,[\"category\"]],[30,0,[\"category\",\"topic_count\"]]]],null],[1,\"\\n\"],[13],[1,\"\\n\"],[10,\"td\"],[14,0,\"reply-count\"],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"reply\"],null]],[1,\"\\n  \"],[8,[39,2],null,[[\"@user\",\"@category\",\"@count\"],[[30,0,[\"user\"]],[30,0,[\"category\"]],[30,0,[\"category\",\"post_count\"]]]],null],[1,\"\\n\"],[13]],[],false,[\"category-link\",\"d-icon\",\"user-summary-category-search\"]]",
    "moduleName": "discourse/plugins/discourse-global-communities/discourse/templates/connectors/user-summary-top-category-row/agc-user-summary-top-category-row.hbs",
    "isStrictMode": false
  });
});