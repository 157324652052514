define("discourse/plugins/discourse-global-communities/discourse/initializers/auto-mention-on-reply", ["exports", "@ember/service", "discourse/lib/plugin-api"], function (_exports, _service, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "agc-auto-mention-on-reply",
    initialize(container) {
      const siteSettings = container.lookup("service:site-settings");
      (0, _pluginApi.withPluginApi)("0.8.31", api => {
        if (siteSettings.automatic_mentions_on_reply) {
          api.modifyClass("service:composer", {
            pluginId: "agc-base-component auto-mention-on-reply",
            currentUser: (0, _service.service)(),
            open(opts) {
              // auto-mention user if:
              // - user is replying to another user
              // - topic has 2+ participants
              // - user is not quoting

              if (opts.post && opts.post.topic && opts.post.topic.participant_count > 1 && !opts.quote && opts.post.username !== this.currentUser.username) {
                opts.quote = `@${opts.post.username} `;
              }
              return this._super(...arguments);
            }
          });
        }
      });
    }
  };
});