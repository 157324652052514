define("discourse/plugins/discourse-global-communities/discourse/controllers/admin-plugins-agc-contacts", ["exports", "@glimmer/tracking", "@ember/controller", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _tracking, _controller, _object, _service, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AdminPluginsAgcContactsController extends _controller.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "groupName", [_tracking.tracked], function () {
      return null;
    }))();
    #groupName = (() => (dt7948.i(this, "groupName"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "content", [_tracking.tracked], function () {
      return null;
    }))();
    #content = (() => (dt7948.i(this, "content"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "loading", [_tracking.tracked], function () {
      return false;
    }))();
    #loading = (() => (dt7948.i(this, "loading"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "saving", [_tracking.tracked], function () {
      return false;
    }))();
    #saving = (() => (dt7948.i(this, "saving"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "saved", [_tracking.tracked], function () {
      return false;
    }))();
    #saved = (() => (dt7948.i(this, "saved"), void 0))();
    queryParams = ["groupName"];
    get csv_data() {
      return this.content;
    }
    set csv_data(value) {
      // this setter is defined to reset the value of `saved` when the csv data changes
      this.content = value;
      this.saved = false;
    }
    get availableGroups() {
      try {
        return JSON.parse(this.siteSettings.agc_csv_contacts_groups);
      } catch {
        return [];
      }
    }
    get selectedGroup() {
      return this.availableGroups.find(group => group.group_name === this.groupName);
    }
    get isGroupApiOnly() {
      return this.selectedGroup && this.selectedGroup.api_only;
    }
    get isEditorDisabled() {
      return !this.selectedGroup || this.loading || this.saving;
    }
    get isSaved() {
      return this.saved;
    }
    get isSaveDisabled() {
      return this.isSaved || this.isEditorDisabled;
    }
    async save() {
      this.saving = true;
      try {
        await (0, _ajax.ajax)(`/admin/plugins/agc/contacts.json`, {
          data: {
            group_name: this.groupName,
            csv_data: this.csv_data
          },
          type: "PUT"
        });
        this.saved = true;
      } catch (error) {
        (0, _ajaxError.popupAjaxError)(error);
      } finally {
        this.saving = false;
      }
    }
    static #_7 = (() => dt7948.n(this.prototype, "save", [_object.action]))();
  }
  _exports.default = AdminPluginsAgcContactsController;
});