define("discourse/plugins/discourse-global-communities/discourse/components/agc-merged-post-footer", ["exports", "discourse/helpers/replace-emoji", "discourse-common/helpers/d-icon", "discourse-i18n", "@ember/component", "@ember/template-factory", "@ember/component/template-only"], function (_exports, _replaceEmoji, _dIcon, _discourseI18n, _component, _templateFactory, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    {{i18n "discourse_gc.merged_post.consolidate_reason" date=@date}}
    <div class="__link">
      <a class="track-link inbound" href={{@url}}>
        {{dIcon "link"}}
        <span>{{replaceEmoji @title}}</span>
      </a>
    </div>
  
  */
  {
    "id": "BnwF87i1",
    "block": "[[[1,\"\\n  \"],[1,[28,[32,0],[\"discourse_gc.merged_post.consolidate_reason\"],[[\"date\"],[[30,1]]]]],[1,\"\\n  \"],[10,0],[14,0,\"__link\"],[12],[1,\"\\n    \"],[10,3],[14,0,\"track-link inbound\"],[15,6,[30,2]],[12],[1,\"\\n      \"],[1,[28,[32,1],[\"link\"],null]],[1,\"\\n      \"],[10,1],[12],[1,[28,[32,2],[[30,3]],null]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@date\",\"@url\",\"@title\"],false,[]]",
    "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-global-communities/discourse/components/agc-merged-post-footer.js",
    "scope": () => [_discourseI18n.i18n, _dIcon.default, _replaceEmoji.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)(undefined, "agc-merged-post-footer"));
});