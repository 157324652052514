define("discourse/plugins/discourse-global-communities/discourse/initializers/application-route", ["exports", "@ember/object", "@ember/service", "@ember/utils", "discourse/controllers/composer", "discourse/lib/plugin-api"], function (_exports, _object, _service, _utils, _composer, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function initialize(api) {
    // When a user without a Discourse account tries to authenticate with Amazon,
    // we do nothing as Amazon does not want to show the Login modal with the
    // associated flash error message. This is because the login template has been
    // override with a custom template displaying information to the user that the
    // forum is invite only.
    api.addBeforeAuthCompleteCallback(options => {
      if (options.requires_invite) {
        const loginPage = api.container.lookup("controller:login-page");
        loginPage?.set("inviteRequiredError", true);
        return false;
      }
      return true;
    });
    api.modifyClass("route:application", Superclass => class extends Superclass {
      static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
      #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
      createNewTopicViaParams() {
        if (typeof window.ReactNativeWebView !== "undefined") {
          this.appEvents.on("composer:cancelled", () => {
            window.ReactNativeWebView.postMessage(JSON.stringify({
              discourse_event: "close_composer"
            }));
          });
          this.appEvents.on("topic:created", post => {
            window.ReactNativeWebView.postMessage(JSON.stringify({
              discourse_event: "topic_created",
              id: post?.topic_id,
              title: post.title
            }));
          });
        }
        return super.createNewTopicViaParams(...arguments);
      }
      static #_2 = (() => dt7948.n(this.prototype, "createNewTopicViaParams", [_object.action]))();
    });
    api.modifyClass("service:composer", {
      pluginId: "discourse-global-communities",
      capabilities: (0, _service.service)(),
      keyValueStore: (0, _service.service)(),
      showToolbar: (0, _object.computed)({
        get() {
          if (document.querySelector(".clear-composer-transitions")) {
            return true;
          }
          const storedVal = this.keyValueStore.get("toolbar-enabled");
          if (this._toolbarEnabled === undefined && storedVal === undefined) {
            // iPhone 6 is 375, anything narrower and toolbar should
            // be default disabled.
            // That said we should remember the state
            this._toolbarEnabled = window.innerWidth > 370 && !this.capabilities.isAndroid;
          }
          return this._toolbarEnabled || storedVal === "true";
        },
        set(key, val) {
          this._toolbarEnabled = val;
          this.keyValueStore.set({
            key: "toolbar-enabled",
            value: val ? "true" : "false"
          });
          return val;
        }
      }),
      actions: {
        cancel() {
          if (document.querySelector(".clear-composer-transitions")) {
            if (typeof window.ReactNativeWebView !== "undefined") {
              window.ReactNativeWebView.postMessage(JSON.stringify({
                discourse_event: "close_composer"
              }));
            }
          } else {
            this.cancelComposer();
          }
        },
        toggle() {
          if (document.querySelector(".clear-composer-transitions")) {
            this.set("currentRouteIndex", this.currentRouteIndex - 1);
            window.history.back();
          } else {
            this.closeAutocomplete();
            if ((0, _utils.isEmpty)(this.get("model.reply")) && (0, _utils.isEmpty)(this.get("model.title"))) {
              this.close();
            } else {
              if (this.get("model.composeState") === _composer.default.OPEN || this.get("model.composeState") === _composer.default.FULLSCREEN) {
                this.shrink();
              } else {
                this.cancelComposer();
              }
            }
            return false;
          }
        }
      }
    });
    api.modifyClass("route:new-topic", {
      pluginId: "discourse-global-communities",
      beforeModel() {
        if (typeof window.ReactNativeWebView !== "undefined") {
          document.documentElement.classList.add("clear-composer-transitions");
        }
        this._super(...arguments);
      }
    });
  }
  var _default = _exports.default = {
    name: "application-route",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.8.31", initialize);
    }
  };
});